import { Button, Input, Form, Space, Popover, Spin, Typography } from 'antd';
import './form-chat.scss';

import Picker from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import AttchMessageFile from 'components/common/icon-svg/attchMessageFile';
import EmojiIcon from 'components/common/icon-svg/emoji';
import { forwardRef, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import RemoveIcon from 'components/common/icon-svg/remove';
import ArticleIcon from 'components/common/icon-svg/article';
import AddIcon from 'components/common/icon-svg/add';
import { useDispatch } from 'react-redux';
import { messageActions } from 'features/message/messageSlice';
import { getSettingUpload } from 'features/incident/api';

const { TextArea } = Input;
const { Text } = Typography;

interface IChatProps {
  indexFile: number;
  handleUploadFile: (fileLists: any) => void;
  activeIncidentId: any;
  handleReadMessage: (incident_id: string) => void;
  onSendMessage: (message: any) => void;
  isLoading: boolean;
  isLoadingListIncident: boolean;
  handleRemoveUpload: (file: any) => void;
  reloadListIncident: () => void;
  FileMessage: any;
  listIncident?: any[];
  inputFileRef: any;
  isClosed: boolean;
  isShowButtonAnswered: boolean;
  isEnableSurveyAnswered: boolean;
  onGetSurvey: (message: any) => void;
}

const FormChat = (
  {
    inputFileRef,
    handleReadMessage,
    handleUploadFile,
    isClosed,
    isShowButtonAnswered,
    onSendMessage,
    activeIncidentId,
    indexFile,
    isLoading,
    handleRemoveUpload,
    reloadListIncident,
    FileMessage,
    onGetSurvey,
    isLoadingListIncident,
    isEnableSurveyAnswered,
  }: IChatProps,
  ref
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const inputRef: any = useRef(null);
  const [showTextChat, setShowTextChat] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [isAllowUpload, setIsAllowUpload] = useState<boolean>(false);

  const onReadMessage = async () => {
    await handleReadMessage(activeIncidentId);
    reloadListIncident();
    return dispatch(messageActions.updateTotalnotification({}));
  };

  const handleClickUpload = useCallback(() => {
    inputFileRef.current?.click?.();
  }, []);

  const onEmojiClick = (event: any, emojiObject: any) => {
    const messageValue: string = form.getFieldValue('message');
    const newMessage: string = messageValue
      ? `${messageValue} ${emojiObject?.emoji}`
      : `${emojiObject?.emoji}`;

    form.setFieldsValue({ message: newMessage });
  };

  const content = () => <Picker onEmojiClick={onEmojiClick} />;
  const onRemoveUpload = (file: any) => {
    handleRemoveUpload(file);
  };

  const onFinish = async (values: any) => {
    let message: any;

    if (typeof values === 'object') {
      message = values.message;
    } else {
      message = values;
    }
    await onSendMessage(message);
    form.resetFields();
    inputRef?.current?.focus();
    await reloadListIncident();
    await handleReadMessage(activeIncidentId);
    return dispatch(messageActions.updateTotalnotification({}));
  };
  let isDisabled;

  useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 300);
  });

  useEffect(() => {
    getSettingUploadFile();
  }, []);

  const getSettingUploadFile = () => {
    getSettingUpload()
      .then((result) => {
        if (result) {
          setIsAllowUpload(result?.data);
        }
      })
      .catch((err) => {});
  };

  const onKeyPress = (e) => {
    if (e.target.value !== '') {
      setShowTextChat(true);
    } else {
      setShowTextChat(false);
    }
  };

  return (
    <Form
      className={indexFile > 0 ? ' form_chat upload_file' : 'form_chat normal'}
      style={{ flexDirection: 'column' }}
      form={form}
      ref={ref}
    >
      <Spin spinning={isLoading}>
        <Form.Item className={indexFile > 0 ? 'chat_area upload_item' : 'chat_area'}>
          {indexFile > 0 && (
            <Form.Item className="emoji_upload">
              <Popover
                className="popover"
                content={content()}
                zIndex={9999}
                destroyTooltipOnHide={false}
              >
                <div className="container_emoji">
                  <EmojiIcon />
                </div>
              </Popover>
            </Form.Item>
          )}
          {showTextChat ? (
            <Form.Item className="item-area-without-message" name="message">
              <TextArea
                disabled={isClosed}
                onKeyPress={onKeyPress}
                onClick={onReadMessage}
                className="input-area-without-message"
                placeholder={t('chat.incident.message.placeholder')}
                //  autoSize={{ minRows: 1, maxRows: 3 }}
                bordered={false}
                maxLength={12000}
                onBlur={() => {
                  window.scrollTo({ left: 0, top: 0 });
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item className="item-area-without-message" name="message">
              <TextArea
                disabled={isClosed}
                onKeyPress={onKeyPress}
                onClick={onReadMessage}
                className="input-area-without-message"
                placeholder={t('chat.incident.message.placeholder')}
                // autoSize={{ minRows: 1, maxRows: 3 }}
                bordered={false}
                maxLength={12000}
                onBlur={() => {
                  window.scrollTo({ left: 0, top: 0 });
                }}
              />
            </Form.Item>
          )}

          {FileMessage?.files?.length > 0 ? (
            <Space style={{ marginTop: '0px' }} className="space-buttonupload">
              <Form.Item
                className={indexFile > 0 ? 'footer_icon ' : 'footer_icon failed'}
                name="files"
                valuePropName="files"
              >
                <div className={indexFile > 0 ? 'upload-message file' : 'upload-message'}>
                  {indexFile > 0 ? (
                    <>
                      {FileMessage?.files?.length > 0 &&
                        FileMessage.files.map((item, index) => {
                          return (
                            <div
                              className="preview_container"
                              style={{ display: indexFile > 0 ? 'block' : 'none' }}
                              key={index}
                            >
                              <div
                                className="article_file"
                                title={`${decodeURIComponent(item.original_name)}`}
                              >
                                <ArticleIcon />
                                <Text className="file-name">
                                  <Text className="name">
                                    {decodeURIComponent(item.original_name.split('.')[0])}
                                  </Text>
                                  .{item.original_name.split('.')[1]}
                                </Text>
                              </div>

                              <RemoveIcon
                                className="img-remove"
                                onClick={() => onRemoveUpload(item)}
                              />
                            </div>
                          );
                        })}

                      <div className="add-container">
                        {isLoading ? (
                          <LoadingOutlined />
                        ) : (
                          <>
                            <AddIcon className="add-image" onClick={handleClickUpload} />
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <Fragment>
                      {isAllowUpload && <AttchMessageFile onClick={handleClickUpload} />}
                    </Fragment>
                  )}

                  <input
                    onChange={(event: any) => handleUploadFile(event.target.files)}
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                  />
                </div>
              </Form.Item>
              {indexFile <= 0 && (
                <Form.Item className="footer_emoji">
                  <Popover
                    className="popover"
                    content={content()}
                    zIndex={9999}
                    destroyTooltipOnHide={false}
                  >
                    <div className="emoji">
                      <EmojiIcon />
                    </div>
                  </Popover>
                </Form.Item>
              )}
            </Space>
          ) : (
            <Space style={{ marginTop: '-11px' }} className="space-buttonupload">
              <Form.Item
                className={indexFile > 0 ? 'footer_icon ' : 'footer_icon failed'}
                name="files"
                valuePropName="files"
              >
                <div className={indexFile > 0 ? 'upload-message file' : 'upload-message'}>
                  {indexFile > 0 ? (
                    <>
                      {FileMessage?.files?.length > 0 &&
                        FileMessage.files.map((item, index) => {
                          return (
                            <div
                              className="preview_container"
                              style={{ display: indexFile > 0 ? 'block' : 'none' }}
                              key={index}
                            >
                              <div
                                className="article_file"
                                title={`${decodeURIComponent(item.original_name)}`}
                              >
                                <ArticleIcon />
                                <Text className="file-name">
                                  <Text className="name">
                                    {decodeURIComponent(item.original_name.split('.')[0])}
                                  </Text>
                                  .{item.original_name.split('.')[1]}
                                </Text>
                              </div>

                              <RemoveIcon
                                className="img-remove"
                                onClick={() => onRemoveUpload(item)}
                              />
                            </div>
                          );
                        })}

                      <div className="add-container">
                        {isLoading ? (
                          <LoadingOutlined />
                        ) : (
                          <>
                            <AddIcon className="add-image" onClick={handleClickUpload} />
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <Fragment>
                      {isAllowUpload && <AttchMessageFile onClick={handleClickUpload} />}
                    </Fragment>
                  )}

                  <input
                    onChange={(event: any) => handleUploadFile(event.target.files)}
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                  />
                </div>
              </Form.Item>
              {indexFile <= 0 && (
                <Form.Item className="footer_emoji">
                  <Popover
                    trigger={['click']}
                    className="popover"
                    content={content()}
                    zIndex={9999}
                    destroyTooltipOnHide={false}
                  >
                    <div className="emoji">
                      <EmojiIcon />
                    </div>
                  </Popover>
                </Form.Item>
              )}
            </Space>
          )}
        </Form.Item>
        {isShowButtonAnswered && isLoadingListIncident === false && (
          <Form.Item
            className="footer_button footer_button_left "
            shouldUpdate={(p, n) => p.message !== n.message}
          >
            <Button
              className="button-end-chat"
              onClick={onGetSurvey}
              type="primary"
              ghost
              disabled={!isEnableSurveyAnswered}
            >
              {isEnableSurveyAnswered
                ? t('chat.incident.survey_chat_btn')
                : t('chat.incident.survey_chat_btn_disable')}
            </Button>
          </Form.Item>
        )}

        <Form.Item
          className="footer_button footer_button_right"
          shouldUpdate={(p, n) => p.message !== n.message}
        >
          {({ getFieldValue }) => {
            const message = getFieldValue('message');

            if (indexFile) {
              if (indexFile > 0) {
                isDisabled = false;
              } else {
                isDisabled = true;
              }
            } else {
              if (!message) {
                isDisabled = true;
              } else {
                isDisabled = false;
              }
            }
            return (
              <Button
                onMouseDown={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      onFinish(values);
                    })
                    .catch(() => null);
                }}
                className="button-send"
                disabled={isDisabled}
              >
                {t('chat.incident.send_btn')}
              </Button>
            );
          }}
        </Form.Item>
      </Spin>
    </Form>
  );
};

export default forwardRef(FormChat);
